/* About Me Section 1 */

#section--1 {
  background-color: var(--color-light);
}

.about__heading {
  font-size: 2.2rem;
}

.about__content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8rem;
}

.about__image {
  border-radius: 8px;
  max-width: 26rem;
}
