/* Button */

.btn {
  padding: 0.4rem 1.6rem;
  border-radius: 3rem;
  background-color: var(--color-accent);
  color: #ffffff;
  cursor: pointer;
  font-size: 1.4rem;
  max-height: 4rem;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.btn:hover {
  background-color: var(--color-background);
  color: var(--color-accent);
  /* transform: scale(1.02); */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  border: 2px solid var(--color-accent);
}

.btn:active {
  transform: scale(0.98);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.btn__secondary {
  padding: 0.4rem 1.6rem;
  border-radius: 3rem;
  background-color: var(--color-background);
  border: 2px solid var(--color-accent);
  color: var(--color-accent);
  cursor: pointer;
  font-size: 1.4rem;
  max-height: 4rem;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.btn__secondary:hover {
  transform: scale(1.03);

  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.btn__secondary::after {
  /* content: " ->"; */
}

.button__container {
  display: flex;
  gap: 2rem;
}
