/* Nav */

.logo {
  max-width: 24rem;
  cursor: pointer;
  margin-left: 0;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 9rem;
  padding: 0 6rem;
  z-index: 1000;
  width: 100%;

  background-color: #ffffff;
}

.nav__toggle {
  display: none;
  font-size: 2rem;
  cursor: pointer;
}

.nav__links {
  display: flex;
  align-items: center;
  gap: 3.2rem;
  list-style: none;
}

.nav__logo {
  height: 4.5rem;
  transition: all 0.3s;
}

.nav__item {
  /* margin-left: 4rem; */
}

.nav__link,
.nav__link:visited {
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;

  cursor: pointer;
  transition: all, 0.3s;
}

.nav__links {
  display: flex;
  align-items: center;
  list-style: none;
}

.nav.sticky .nav__links {
  top: 100%;
}

.nav.sticky {
  position: fixed;
  top: 0;
  background-color: #ffffff;
  opacity: 0.8;

  /* padding: 5.4rem 6rem; */
  box-sizing: border-box;
  height: 9rem;
  z-index: 1000;
}

.button__container--nav {
  display: flex;
  gap: 3rem;
}
