/* Header */

.section__hero {
  padding: 90px 0 140px 0;
}

.hero {
  max-width: 115rem;
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 2fr;
  padding: 0 3.2rem;
  margin: 0 auto;
  gap: 3rem;
}

.hero__heading {
  font-size: 4.4rem;

  margin-bottom: 3.2rem;
}

.hero__description {
  margin-bottom: 4.8rem;
}

.hero__img-block {
  display: flex;
  justify-content: center;
  margin-left: 20px;
}

.hero__image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px;
  background-color: var(--color-light);
  border-radius: 50%;
}

.hero__img-svg {
  width: 80px;
  stroke: var(--color-accent);
}

.header__image {
  align-self: center;
  justify-self: center;
  margin-left: 4rem;
  grid-column: 2 / 3;

  opacity: 0;
  transform: translateX(70%);
  animation: slideIn 0.7s ease-in-out forwards;
  background-color: var(--color-light);
  padding: 10rem;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
}

@keyframes slideIn {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.btn__container {
  display: flex;
  justify-content: space-between;
}
