/* Features / Section 2 */

#section--2 {
  background-color: white;
}

.features {
  margin-top: 10rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 6rem;
  column-gap: 0rem;
}

.features__description {
  align-self: flex-start;
  justify-self: center;
  width: 60%;
}

.features__heading {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.features__text {
  font-size: 1.6rem;
}

.icon__box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  border-radius: 50%;
  width: 60px;
  height: 60px;

  background-color: var(--color-light);
}

.features__icon {
  width: 2.4rem;
  stroke: var(--color-accent);
}
