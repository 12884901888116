.section {
  padding: 12rem 0;
  transition: transform 1s, opacity 1s;
}

.section__title {
  margin-bottom: 12rem;
  max-width: 80rem;
  margin: 0 auto 8rem auto;
  position: relative;
}

.section__title::before {
  position: absolute;
  content: "";
  top: 50%;
  left: -20rem;
  height: 16rem;
  width: 16rem;
  border-radius: 50%;

  background-color: var(--color-accent);
  transform: translateY(-50%);
  clip-path: circle(50% at 50% 50%);
}

.section--hidden {
  opacity: 0;
  transform: translateY(4rem);
}

.eyebrow {
  font-size: 2.2rem;
  font-weight: 500;
  color: var(--color-accent);
}

.section__short {
  margin-bottom: 2rem !important;
}
