@import url("/css/header.css");
@import url("/css/slider.css");
@import url("/css/nav.css");
@import url("/css/queries.css");
@import url("/css/section.css");
@import url("/css/btn.css");
@import url("/css/about.css");
@import url("/css/form.css");
@import url("/css/tabs.css");
@import url("/css/skills.css");

:root {
  --color-background: #ffffff;
  --color-accent: #0056b3;
  --color-accent-background: #a6c6e9;
  --color-text: #333333;
  --color-light: #e3f6ff;
  --color-dark: rgba(0, 0, 0, 0.7);
  --gradient-primary: linear-gradient(to top left, #5f76b7, #9ac1e4);
  --gradient-secondary: linear-gradient(to top left, #c3db5c, #8f9c58);
}

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--color-text);
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

/* General Elements */

.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

p {
  font-size: 1.7rem;
}

.icon__background {
  color: var(--color-);
}

h4 {
  font-size: 2.4;
}

h3 {
  font-size: 2.8rem;
}

.hidden {
  display: none;
}
