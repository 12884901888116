/* form */

.button--close svg {
  stroke: #dc3545;
}
.button--close {
  position: absolute;
  left: 10px;
  top: 10px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button--close svg:hover {
  stroke: #f20f26;
}

.button--close:hover {
  transform: scale(1.04);
}

.modal {
  /* display: none; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  z-index: 500;
}

.modal__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  z-index: 1;
}

.modal__content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: auto;
  top: 14rem;
  padding: 10rem 20rem 10rem 14rem;
  border-radius: 8px;
  /* width: 90%; */
  max-width: 600px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.modal__content h2 {
  font-size: 2.6rem;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.6rem;
}

.contact-info__detail {
  font-size: 1.4rem;
  width: 200px;
}

.form__label {
  font-size: 2rem;
}
.form__info {
  margin-bottom: 3rem;
}
.form__icon {
  height: 30px;
  width: 30px;
}

.btn--form {
  padding: 1.4rem;
  margin-top: 2rem;
  display: none;
}

label {
  display: block;
  margin: 10px 0 5px;
}

input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}
