/* rem and em do Not depend on html font-size in media queries
Instead, 1rem = 1 em = 16px */

@media (max-width: 1250px) {
  /* Slider */
  .slider__content {
    max-width: 800px;
  }

  #section--3 .slider {
    max-width: 100rem;
  }
  #section--3 .slider__img {
    max-width: 200px;
    height: 160px;
  }
}

@media (max-width: 1000px) {
  .nav__toggle {
    display: block;
    height: 40px;
    width: 40px;
    font-size: 3rem;
  }

  .nav__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;

    top: 109.18px;
    right: 0;
    background-color: #ffff;
    opacity: 1;
    padding-bottom: 2rem;
    width: 200px;
    justify-self: center;
    border-radius: 2rem;
    padding-left: 0;
    border-radius: 0 0 0 2rem;
    gap: 4rem;
  }

  .nav.sticky {
    opacity: 1;
  }

  .button__container--nav {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  .section__title {
    margin-bottom: 4rem !important;
  }

  /* Skills */

  .features {
    margin-top: 6rem !important;
  }

  /* Slider */

  #section--3 .slider {
    max-width: 75rem;
  }

  #section--3 .slider__content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    justify-content: center;
    width: 80%;
    border-radius: 2rem;
    padding: 20px;
    height: 28rem;
  }

  .slider__img {
    max-width: 200px;
    margin-bottom: 1.8rem;
  }

  .slider__text--block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .slider__heading {
    font-size: 2.4rem;
  }

  .slider__text {
    font-size: 1.2rem;
    margin-bottom: 1.4rem;
    text-align: center;
    display: none;
  }

  .features__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 0;
    margin-bottom: 2rem;
  }

  .slider__btn--container {
    display: flex;
    grid-column: 1;
  }

  .slide {
    top: 10px;
  }

  .slider__btn {
    width: 45px;
    height: 45px;
    bottom: 50%;
  }

  .dots {
    bottom: 120px;
  }

  .dots__dot {
    height: 1.4rem;
    width: 1.4rem;
  }
}

@media (max-width: 850px) {
  /* Form */
  body .modal__content {
    max-width: 450px;
    padding: 5rem 14rem 10rem 8rem;
  }

  /* Section */

  .section__heading {
    font-size: 2.4rem;
  }
  /* About */

  #section--1 .about__content {
    display: grid;
    row-gap: 4.4rem;
  }

  #section--1 .about__text {
    grid-row: 2;
  }

  #section--1 .about__content p {
    margin-top: 0;
    font-size: 1.6rem;
    font-weight: 400;
  }

  #section--1 .about__image {
    grid-row: 1;
    border-radius: 8px;
    max-width: 160px;
  }

  /* Skills */

  #section--2 .features {
    margin-top: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    row-gap: 6rem;
    column-gap: 0rem;
  }

  #section--2 .features__description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    align-self: center;
    text-align: center;
  }
}

@media (max-width: 760px) {
  /* Hero */
  .hero {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hero__text-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
  }

  .hero__img-block {
    margin-left: 0;
  }

  .hero__image {
    padding: 60px;
  }

  .hero__img-svg {
    width: 60px;
  }

  .hero__heading {
    text-align: center;
    font-size: 3.6rem;
  }

  .hero__description {
    text-align: center;
  }

  body .eyebrow {
    font-size: 1.8rem;
  }

  body .section__short {
    margin-bottom: 6rem !important;
  }

  /* slider */
  #section--3 .slider {
    max-width: 50rem;
  }

  #section--3 .slider__content {
    height: 43rem;
    width: 36rem;
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: center;
    box-shadow: none;
  }

  #section--3.slider__content:hover {
    transform: none !important;
    box-shadow: none !important;
  }

  #section--3 .slider__img {
    width: 170px;
    height: 140px;
  }

  .slider__text--block {
    align-items: center;
  }
  .slider__heading {
    text-align: center;
  }

  .features__item svg {
    height: 30px;
    width: 30px;
  }

  #section--3 .btn__secondary {
    font-size: 1.1rem;
  }

  #section--3 .btn {
    font-size: 1.1rem;
  }

  .dots {
    bottom: 65px;
  }
}

@media (max-width: 500px) {
  /* Section */
  body .section {
    padding-top: 4rem;
  }

  /* Nav */

  .nav {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .logo {
    max-width: 15rem;
    cursor: pointer;
    src: url("/img/logo-small.png");
    margin-left: 0;
  }

  .nav__links {
    padding-top: 2rem;
    top: 100px;
    width: 100%;

    border-radius: 0 0 2rem 2rem;

    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    opacity: 1;
  }

  /* Header */

  .section__hero {
    padding: 1.6rem 0 6.6rem 0;
  }
  .hero {
    gap: 5rem;
  }
  .hero__heading {
    font-size: 2.4rem;
    margin-bottom: 1.4rem;
  }
  .hero__description {
    margin-bottom: 2.6rem;
  }

  .hero__text-box {
    margin-bottom: 0;
  }

  .hero__image {
    padding: 35px;
  }

  .hero__img-svg {
    width: 40px;
  }

  .header__title .btn {
    font-size: 1.4rem;
  }

  body .section__short {
    margin-bottom: 4rem !important;
  }

  /* Form */
  body .modal__content {
    width: 360px;
    padding: 5rem 14rem 10rem 3.4rem;
  }

  body .modal__content h2 {
    font-size: 1.8rem;
  }
  body .form__info label {
    font-size: 1.6rem;
  }

  body .button--close {
    height: 24px;
    width: 24px;
  }
  /* Features */

  #section--2 .features__description {
    width: 100%;
  }

  /* Slider */

  #section--3 .slider {
    max-width: 36rem;
  }
  #section--3 .slider__content {
    width: 30rem;
    box-shadow: none;
    transform: scale(0.95);
  }

  .slider__content:hover {
    transform: none !important;
    box-shadow: none !important;
  }

  .slider__btn {
    width: 24px;
    height: 24px;
    font-size: 1.4rem;
    box-shadow: none !important;
  }

  .slider__btn:hover {
    background-color: var(--color-accent);
  }

  #section--3 .slider__img {
    width: 150px;
    height: 120px;
  }

  #section--3 .features__item {
    margin-left: 0px;
  }
  .features__item svg {
    height: 30px !important;
    width: 30px !important;
  }

  #section--3 .btn__secondary {
    font-size: 1.1rem;
  }
  #section--3 .btn {
    font-size: 1.1rem;
  }
  .dots {
    bottom: 80px;
  }
  /* .slider__btn--container; */
}
