/* Slider */
#section--3 {
  background-color: var(--color-light);
}

.slider {
  max-width: 120rem;

  height: 56rem;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.slide {
  position: absolute;
  height: 45rem;
  top: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.4s, box-shadow 0.4s;
}

.slider__content {
  display: flex;
  align-items: flex-start;
  gap: 3rem;
  padding: 30px;
  border-radius: 1.4rem;
  width: 80%;
  height: 34rem;
  background-color: var(--color-background);
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.slider__content:hover {
  transform: scale(1.01);
}

.slider__text {
  font-size: 1.4rem;
  margin-right: 1.9rem;
  margin-bottom: 2rem;
}

.features__list {
  display: flex;
  gap: 1.4rem;
  align-items: center;
  margin-bottom: 2.8rem;
}

.features__item {
  padding: 0.4rem 0rem 0.4rem 0rem;
  display: flex;
  align-items: center;

  gap: 4px;
}

.features__item-text {
  font-size: 1.1rem;
  font-weight: 600;
  color: black;
}

.features__item svg {
  height: 36px;
  width: 36px;
  flex-shrink: 0;
}

.checkmark {
  color: var(--color-accent);
  margin-right: 0.5em;
  font-weight: bold;
  font-size: 20px;
  max-width: 20px;
}

.btn--slider {
  margin-top: 24px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  width: 12rem;
}

.slider__heading {
  /* justify-self: center; */
  margin-bottom: 1.6rem;
}

.slider__img {
  max-width: 300px;
  height: 200px;
  border-radius: 1rem;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.slider__btn {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 40%;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--color-accent);
  color: #f8f8f5;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.slider__btn:hover {
  opacity: 0.8;
}

.slider__btn--container {
  display: flex;
  gap: 2rem;
}

.slider__btn--right {
  right: 1px;
}

.slider__btn--left {
  left: 1px;
}

.dots {
  position: absolute;
  bottom: 30px;
  transform: translateX(-50%);
  left: 50%;
  display: flex;
}

.dots__dot {
  border: none;
  background-color: rgba(199, 199, 199, 0.7);
  opacity: 0.7;
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 50%;
  margin-right: 1.75rem;
  cursor: pointer;
  transition: all 0.5s;
  display: inline-block;
}

.dots__dot:last-child {
  margin: 0;
}

.dots__dot--active {
  background-color: var(--color-accent);
  opacity: 1;
}
