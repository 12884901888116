.section__hero {
  padding: 90px 0 140px;
}

.hero {
  grid-template-columns: 3fr 2fr;
  align-items: center;
  gap: 3rem;
  max-width: 115rem;
  margin: 0 auto;
  padding: 0 3.2rem;
  display: grid;
}

.hero__heading {
  margin-bottom: 3.2rem;
  font-size: 4.4rem;
}

.hero__description {
  margin-bottom: 4.8rem;
}

.hero__img-block {
  justify-content: center;
  margin-left: 20px;
  display: -ms-flexbox;
  display: flex;
}

.hero__image {
  background-color: var(--color-light);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 80px;
  display: -ms-flexbox;
  display: flex;
}

.hero__img-svg {
  width: 80px;
  stroke: var(--color-accent);
}

.header__image {
  opacity: 0;
  background-color: var(--color-light);
  border-radius: 50%;
  grid-column: 2 / 3;
  place-self: center;
  width: 10rem;
  height: 10rem;
  margin-left: 4rem;
  padding: 10rem;
  animation: .7s ease-in-out forwards slideIn;
  transform: translateX(70%);
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.btn__container {
  justify-content: space-between;
  display: -ms-flexbox;
  display: flex;
}

#section--3 {
  background-color: var(--color-light);
}

.slider {
  max-width: 120rem;
  height: 56rem;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.slide {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45rem;
  transition: transform .4s, box-shadow .4s;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 70px;
}

.slider__content {
  background-color: var(--color-background);
  border-radius: 1.4rem;
  align-items: flex-start;
  gap: 3rem;
  width: 80%;
  height: 34rem;
  padding: 30px;
  transition: transform .3s, box-shadow .3s;
  display: -ms-flexbox;
  display: flex;
  box-shadow: 0 3px 10px #0003;
}

.slider__content:hover {
  transform: scale(1.01);
}

.slider__text {
  margin-bottom: 2rem;
  margin-right: 1.9rem;
  font-size: 1.4rem;
}

.features__list {
  align-items: center;
  gap: 1.4rem;
  margin-bottom: 2.8rem;
  display: -ms-flexbox;
  display: flex;
}

.features__item {
  align-items: center;
  gap: 4px;
  padding: .4rem 0;
  display: -ms-flexbox;
  display: flex;
}

.features__item-text {
  color: #000;
  font-size: 1.1rem;
  font-weight: 600;
}

.features__item svg {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
}

.checkmark {
  color: var(--color-accent);
  max-width: 20px;
  margin-right: .5em;
  font-size: 20px;
  font-weight: bold;
}

.btn--slider {
  width: 12rem;
  margin-top: 24px;
  box-shadow: 0 6px 15px #0003;
}

.slider__heading {
  margin-bottom: 1.6rem;
}

.slider__img {
  border-radius: 1rem;
  max-width: 300px;
  height: 200px;
  box-shadow: 0 6px 15px #0003;
}

.slider__btn {
  all: unset;
  background-color: var(--color-accent);
  color: #f8f8f5;
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  font-size: 2rem;
  transition: all .2s;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 40%;
  transform: translateY(-50%);
  box-shadow: 0 4px 8px #0003;
}

.slider__btn:hover {
  opacity: .8;
}

.slider__btn--container {
  gap: 2rem;
  display: -ms-flexbox;
  display: flex;
}

.slider__btn--right {
  right: 1px;
}

.slider__btn--left {
  left: 1px;
}

.dots {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.dots__dot {
  opacity: .7;
  cursor: pointer;
  background-color: #c7c7c7b3;
  border: none;
  border-radius: 50%;
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 1.75rem;
  transition: all .5s;
  display: inline-block;
}

.dots__dot:last-child {
  margin: 0;
}

.dots__dot--active {
  background-color: var(--color-accent);
  opacity: 1;
}

.logo {
  cursor: pointer;
  max-width: 24rem;
  margin-left: 0;
}

.nav {
  z-index: 1000;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 9rem;
  padding: 0 6rem;
  display: -ms-flexbox;
  display: flex;
}

.nav__toggle {
  cursor: pointer;
  font-size: 2rem;
  display: none;
}

.nav__links {
  align-items: center;
  gap: 3.2rem;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
}

.nav__logo {
  height: 4.5rem;
  transition: all .3s;
}

.nav__link, .nav__link:visited {
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;
  transition: all, all .3s;
}

.nav__links {
  align-items: center;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
}

.nav.sticky .nav__links {
  top: 100%;
}

.nav.sticky {
  opacity: .8;
  box-sizing: border-box;
  z-index: 1000;
  background-color: #fff;
  height: 9rem;
  position: fixed;
  top: 0;
}

.button__container--nav {
  gap: 3rem;
  display: -ms-flexbox;
  display: flex;
}

@media (width <= 1250px) {
  .slider__content {
    max-width: 800px;
  }

  #section--3 .slider {
    max-width: 100rem;
  }

  #section--3 .slider__img {
    max-width: 200px;
    height: 160px;
  }
}

@media (width <= 1000px) {
  .nav__toggle {
    width: 40px;
    height: 40px;
    font-size: 3rem;
    display: block;
  }

  .nav__links {
    opacity: 1;
    background-color: #fff;
    border-radius: 0 0 0 2rem;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    gap: 4rem;
    width: 200px;
    padding-bottom: 2rem;
    padding-left: 0;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: 109.18px;
    right: 0;
  }

  .nav.sticky {
    opacity: 1;
  }

  .button__container--nav {
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 4rem;
    display: -ms-flexbox;
    display: flex;
  }

  .section__title {
    margin-bottom: 4rem !important;
  }

  .features {
    margin-top: 6rem !important;
  }

  #section--3 .slider {
    max-width: 75rem;
  }

  #section--3 .slider__content {
    border-radius: 2rem;
    grid-template-columns: 1fr 3fr;
    justify-content: center;
    width: 80%;
    height: 28rem;
    padding: 20px;
    display: grid;
  }

  .slider__img {
    max-width: 200px;
    margin-bottom: 1.8rem;
  }

  .slider__text--block {
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
    display: -ms-flexbox;
    display: flex;
  }

  .slider__heading {
    font-size: 2.4rem;
  }

  .slider__text {
    text-align: center;
    margin-bottom: 1.4rem;
    font-size: 1.2rem;
    display: none;
  }

  .features__list {
    grid-template-columns: 1fr 1fr;
    row-gap: 0;
    margin-bottom: 2rem;
    display: grid;
  }

  .slider__btn--container {
    grid-column: 1;
    display: -ms-flexbox;
    display: flex;
  }

  .slide {
    top: 10px;
  }

  .slider__btn {
    width: 45px;
    height: 45px;
    bottom: 50%;
  }

  .dots {
    bottom: 120px;
  }

  .dots__dot {
    width: 1.4rem;
    height: 1.4rem;
  }
}

@media (width <= 850px) {
  body .modal__content {
    max-width: 450px;
    padding: 5rem 14rem 10rem 8rem;
  }

  .section__heading {
    font-size: 2.4rem;
  }

  #section--1 .about__content {
    row-gap: 4.4rem;
    display: grid;
  }

  #section--1 .about__text {
    grid-row: 2;
  }

  #section--1 .about__content p {
    margin-top: 0;
    font-size: 1.6rem;
    font-weight: 400;
  }

  #section--1 .about__image {
    border-radius: 8px;
    grid-row: 1;
    max-width: 160px;
  }

  #section--2 .features {
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6rem 0;
    margin-top: 8rem;
    display: -ms-flexbox;
    display: flex;
  }

  #section--2 .features__description {
    text-align: center;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (width <= 760px) {
  .hero {
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
  }

  .hero__text-box {
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    display: -ms-flexbox;
    display: flex;
  }

  .hero__img-block {
    margin-left: 0;
  }

  .hero__image {
    padding: 60px;
  }

  .hero__img-svg {
    width: 60px;
  }

  .hero__heading {
    text-align: center;
    font-size: 3.6rem;
  }

  .hero__description {
    text-align: center;
  }

  body .eyebrow {
    font-size: 1.8rem;
  }

  body .section__short {
    margin-bottom: 6rem !important;
  }

  #section--3 .slider {
    max-width: 50rem;
  }

  #section--3 .slider__content {
    width: 36rem;
    height: 43rem;
    box-shadow: none;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;
    gap: 0;
    display: -ms-flexbox;
    display: flex;
  }

  #section--3.slider__content:hover {
    box-shadow: none !important;
    transform: none !important;
  }

  #section--3 .slider__img {
    width: 170px;
    height: 140px;
  }

  .slider__text--block {
    align-items: center;
  }

  .slider__heading {
    text-align: center;
  }

  .features__item svg {
    width: 30px;
    height: 30px;
  }

  #section--3 .btn__secondary, #section--3 .btn {
    font-size: 1.1rem;
  }

  .dots {
    bottom: 65px;
  }
}

@media (width <= 500px) {
  body .section {
    padding-top: 4rem;
  }

  .nav {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .logo {
    cursor: pointer;
    src: url("logo-small.5dfea2ce.png");
    max-width: 15rem;
    margin-left: 0;
  }

  .nav__links {
    opacity: 1;
    border-radius: 0 0 2rem 2rem;
    width: 100%;
    padding-top: 2rem;
    top: 100px;
  }

  .section__hero {
    padding: 1.6rem 0 6.6rem;
  }

  .hero {
    gap: 5rem;
  }

  .hero__heading {
    margin-bottom: 1.4rem;
    font-size: 2.4rem;
  }

  .hero__description {
    margin-bottom: 2.6rem;
  }

  .hero__text-box {
    margin-bottom: 0;
  }

  .hero__image {
    padding: 35px;
  }

  .hero__img-svg {
    width: 40px;
  }

  .header__title .btn {
    font-size: 1.4rem;
  }

  body .section__short {
    margin-bottom: 4rem !important;
  }

  body .modal__content {
    width: 360px;
    padding: 5rem 14rem 10rem 3.4rem;
  }

  body .modal__content h2 {
    font-size: 1.8rem;
  }

  body .form__info label {
    font-size: 1.6rem;
  }

  body .button--close {
    width: 24px;
    height: 24px;
  }

  #section--2 .features__description {
    width: 100%;
  }

  #section--3 .slider {
    max-width: 36rem;
  }

  #section--3 .slider__content {
    width: 30rem;
    box-shadow: none;
    transform: scale(.95);
  }

  .slider__content:hover {
    box-shadow: none !important;
    transform: none !important;
  }

  .slider__btn {
    width: 24px;
    height: 24px;
    font-size: 1.4rem;
    box-shadow: none !important;
  }

  .slider__btn:hover {
    background-color: var(--color-accent);
  }

  #section--3 .slider__img {
    width: 150px;
    height: 120px;
  }

  #section--3 .features__item {
    margin-left: 0;
  }

  .features__item svg {
    width: 30px !important;
    height: 30px !important;
  }

  #section--3 .btn__secondary, #section--3 .btn {
    font-size: 1.1rem;
  }

  .dots {
    bottom: 80px;
  }
}

.section {
  padding: 12rem 0;
  transition: transform 1s, opacity 1s;
}

.section__title {
  max-width: 80rem;
  margin: 0 auto 8rem;
  position: relative;
}

.section__title:before {
  content: "";
  background-color: var(--color-accent);
  clip-path: circle(50%);
  border-radius: 50%;
  width: 16rem;
  height: 16rem;
  position: absolute;
  top: 50%;
  left: -20rem;
  transform: translateY(-50%);
}

.section--hidden {
  opacity: 0;
  transform: translateY(4rem);
}

.eyebrow {
  color: var(--color-accent);
  font-size: 2.2rem;
  font-weight: 500;
}

.section__short {
  margin-bottom: 2rem !important;
}

.btn {
  background-color: var(--color-accent);
  color: #fff;
  cursor: pointer;
  border: 2px solid #0000;
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  max-height: 4rem;
  padding: .4rem 1.6rem;
  font-size: 1.4rem;
  text-decoration: none;
  transition: all .3s ease-in-out;
  display: -ms-flexbox;
  display: flex;
}

.btn:hover {
  background-color: var(--color-background);
  color: var(--color-accent);
  border: 2px solid var(--color-accent);
  box-shadow: 0 8px 15px #0000001a;
}

.btn:active {
  transform: scale(.98);
  box-shadow: 0 4px 8px #0003;
}

.btn__secondary {
  background-color: var(--color-background);
  border: 2px solid var(--color-accent);
  color: var(--color-accent);
  cursor: pointer;
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  max-height: 4rem;
  padding: .4rem 1.6rem;
  font-size: 1.4rem;
  text-decoration: none;
  transition: all .3s ease-in-out;
  display: -ms-flexbox;
  display: flex;
}

.btn__secondary:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 15px #00000026;
}

.button__container {
  gap: 2rem;
  display: -ms-flexbox;
  display: flex;
}

#section--1 {
  background-color: var(--color-light);
}

.about__heading {
  font-size: 2.2rem;
}

.about__content {
  justify-content: center;
  align-items: center;
  gap: 8rem;
  display: -ms-flexbox;
  display: flex;
}

.about__image {
  border-radius: 8px;
  max-width: 26rem;
}

.button--close svg {
  stroke: #dc3545;
}

.button--close {
  cursor: pointer;
  width: 40px;
  height: 40px;
  transition: all .3s;
  position: absolute;
  top: 10px;
  left: 10px;
}

.button--close svg:hover {
  stroke: #f20f26;
}

.button--close:hover {
  transform: scale(1.04);
}

.modal {
  z-index: 500;
  width: 100%;
  height: 110%;
  position: fixed;
  top: 0;
  left: 0;
}

.modal__overlay {
  backdrop-filter: blur(5px);
  z-index: 1;
  background: #000000b3;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal__content {
  z-index: 1;
  background-color: #fff;
  border-radius: 8px;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 600px;
  margin: auto;
  padding: 10rem 20rem 10rem 14rem;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  top: 14rem;
  box-shadow: 0 4px 15px #0000004d;
}

.modal__content h2 {
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.6rem;
  font-size: 2.6rem;
}

.contact-info__detail {
  width: 200px;
  font-size: 1.4rem;
}

.form__label {
  font-size: 2rem;
}

.form__info {
  margin-bottom: 3rem;
}

.form__icon {
  width: 30px;
  height: 30px;
}

.btn--form {
  margin-top: 2rem;
  padding: 1.4rem;
  display: none;
}

label {
  margin: 10px 0 5px;
  display: block;
}

textarea, input[type="email"] {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
}

#section--4 {
  background-color: #fff;
}

.tabs {
  background-color: var(--color-background);
  border-radius: 4px;
  max-width: 1000px;
  margin: 18rem auto 0;
}

.tabs__btn {
  margin-right: 2rem;
  padding: 8px 5rem;
}

.tabs__btn:last-child {
  margin-right: 0;
}

.tabs__btn--active {
  background-color: var(--color-accent);
  transform: translateY(-20%);
}

.tabs__btns {
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  transform: translateY(50%);
}

.tabs__content {
  background-color: var(--color-light);
  padding: 6.5rem 7rem;
  display: none;
}

.tabs__content--active {
  grid-template-columns: 7rem 1fr;
  column-gap: 4rem;
  display: grid;
}

.tabs__header {
  align-self: center;
  font-size: 2.25rem;
  font-weight: 500;
}

.tabs__content--active p {
  grid-column: 2;
  font-size: 1.6rem;
}

.tabs__content--active svg {
  max-width: 6rem;
}

.tabs__icon {
  background-color: var(--color-background);
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}

#section--2 {
  background-color: #fff;
}

.features {
  grid-template-columns: 1fr 1fr;
  gap: 6rem 0;
  margin-top: 10rem;
  display: grid;
}

.features__description {
  place-self: flex-start center;
  width: 60%;
}

.features__heading {
  margin-bottom: 1rem;
  font-size: 1.8rem;
}

.features__text {
  font-size: 1.6rem;
}

.icon__box {
  background-color: var(--color-light);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-bottom: 2rem;
  display: -ms-flexbox;
  display: flex;
}

.features__icon {
  width: 2.4rem;
  stroke: var(--color-accent);
}

:root {
  --color-background: #fff;
  --color-accent: #0056b3;
  --color-accent-background: #a6c6e9;
  --color-text: #333;
  --color-light: #e3f6ff;
  --color-dark: #000000b3;
  --gradient-primary: linear-gradient(to top left, #5f76b7, #9ac1e4);
  --gradient-secondary: linear-gradient(to top left, #c3db5c, #8f9c58);
}

* {
  box-sizing: border-box;
  color: var(--color-text);
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

p {
  font-size: 1.7rem;
}

.icon__background {
  color: var(--color-);
}

h4 {
  font-size: 2.4px;
}

h3 {
  font-size: 2.8rem;
}

.hidden {
  display: none;
}
/*# sourceMappingURL=index.cb58f864.css.map */
